import { createSlice } from '@reduxjs/toolkit'

const initialProfilesState = {
	listLoading: false,
	actionsLoading: false,
	totalCount: 0,
	entities: {},
	lastError: null,
}
export const callTypes = {
	list: 'list',
	action: 'action',
}

export const profileSlice = createSlice({
	name: 'profile',
	initialState: initialProfilesState,
	reducers: {
		catchError: (state, action) => {
			state.error = `${action.payload.error}`
			if (action.payload.callType === callTypes.list) {
				state.listLoading = false
			} else {
				state.actionsLoading = false
			}
		},
		startCall: (state, action) => {
			state.error = null
			if (action.payload.callType === callTypes.list) {
				state.listLoading = true
			} else {
				state.actionsLoading = true
			}
		},
		// getProfileById
		profileFetched: (state, action) => {
			state.listLoading = false
			state.entities = action.payload.entities
			state.error = null
		},
		// createProfile
		profileCreated: (state, action) => {
			state.actionsLoading = false
			state.error = null
			state.entities.push(action.payload.profile)
		},
		// updateProfile
		profileUpdated: (state, action) => {
			state.error = null
			state.actionsLoading = false
			state.entities = { ...state.entities, ...action.payload.profile }
		},
		// updateProfile
		licenseUpdated: (state, action) => {
			console.log(action.payload)
			state.error = null
			state.actionsLoading = false
			state.entities.is_licensed = action.payload
		},
		// deleteProfile
		profileDeleted: (state, action) => {
			state.error = null
			state.actionsLoading = false
			state.entities = state.entities.filter(
				(el) => el.id !== action.payload.id,
			)
		},
		// deleteProfiles
		profilesDeleted: (state, action) => {
			state.error = null
			state.actionsLoading = false
			state.entities = state.entities.filter(
				(el) => !action.payload.ids.includes(el.id),
			)
		},
		// profileUpdateState
		profileStatusUpdated: (state, action) => {
			state.actionsLoading = false
			state.error = null
			const { ids, status } = action.payload
			state.entities = state.entities.map((entity) => {
				if (ids.findIndex((id) => id === entity.id) > -1) {
					entity.status = status
				}
				return entity
			})
		},
	},
})
