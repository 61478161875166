import axios from 'axios'

export const PROFILE_URL = process.env.REACT_APP_API_TUTOR_URL + '/profile'
export const STUDENT_PROFILE_URL =
	process.env.REACT_APP_API_STUDENT_URL + '/profile'

// CREATE =>  POST: add a new profile to the server
export function createProfile(profile) {
	return axios.post(PROFILE_URL, { profile })
}

// READ
export function getAllProfile() {
	return axios.get(PROFILE_URL)
}

export function getProfileById(profileId) {
	return axios.get(`${PROFILE_URL}/${profileId}`)
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findProfile() {
	return axios.get(`${PROFILE_URL}`)
}

// UPDATE => PUT: update the procuct on the server
export function updateProfile(profile) {
	return axios.put(`${PROFILE_URL}`, profile)
}

// UPDATE => PUT: update the procuct on the server
export function tutorLicense(reference) {
	return axios.put(`${PROFILE_URL}/tutorLicense`, { reference })
}

// UPDATE Status
export function updateStatusForProfile(ids, status) {
	return axios.post(`${PROFILE_URL}/updateStatusForProfile`, {
		ids,
		status,
	})
}

export function findStudentProfile() {
	return axios.get(`${STUDENT_PROFILE_URL}`)
}
