import { all } from 'redux-saga/effects'
import { combineReducers } from 'redux'

import * as auth from '../app/modules/Auth/_redux/authRedux'
import { coursesSlice } from '../app/modules/Course/_redux/courses/coursesSlice'
import { tagsSlice } from '../app/modules/Course/_redux/tags/tagsSlice'
import { programsSlice } from '../app/modules/Course/_redux/programs/programsSlice'
import { curriculumsSlice } from '../app/modules/Course/_redux/curriculums/curriculumsSlice'
import { lessonsSlice } from '../app/modules/Course/_redux/lessons/lessonsSlice'
import { categoriesSlice } from '../app/modules/Course/_redux/categories/categoriesSlice'
import { settingsSlice } from '../app/modules/Settings/_redux/settings/settingsSlice'
import { profileSlice } from '../app/modules/Settings/_redux/profile/profileSlice'
import { plansSlice } from '../app/modules/Settings/_redux/plans/plansSlice'
import { taxratesSlice } from '../app/modules/Settings/_redux/taxrates/taxratesSlice'
import { enrolmentSlice } from '../app/modules/Student/Courses/_redux/enrolment/EnrolmentSlice'
import { subscriptionsSlice } from '../app/modules/Subscriptions/_redux/subscriptions/subscriptionsSlice'
import { cohortsSlice } from '../app/modules/Cohort/_redux/cohorts/cohortsSlice'

export const rootReducer = combineReducers({
	auth: auth.reducer,
	courses: coursesSlice.reducer,
	tags: tagsSlice.reducer,
	curriculums: curriculumsSlice.reducer,
	lessons: lessonsSlice.reducer,
	categories: categoriesSlice.reducer,
	programs: programsSlice.reducer,
	settings: settingsSlice.reducer,
	profile: profileSlice.reducer,
	plans: plansSlice.reducer,
	taxrates: taxratesSlice.reducer,
	enrolments: enrolmentSlice.reducer,
	subscriptions: subscriptionsSlice.reducer,
	cohorts: cohortsSlice.reducer,
})

export function* rootSaga() {
	yield all([auth.saga()])
}
