import React, { useRef, useEffect, useState } from 'react'
import { shallowEqual, useSelector, connect, useDispatch } from 'react-redux'
import { LayoutSplashScreen } from '../../../../_metronic/layout/_core/MetronicSplashScreen'
import * as auth from './authRedux'
import { getUserByToken } from './authCrud'

const mapState = (state) => ({ auth: state.auth })
const connector = connect(mapState, auth.actions)

const AuthInit = (props) => {
	const didRequest = useRef(false)
	const dispatch = useDispatch()
	const [showSplashScreen, setShowSplashScreen] = useState(true)
	const authToken = useSelector(({ auth }) => auth.authToken, shallowEqual)
	const user = useSelector(({ auth }) => auth.user, shallowEqual)

	// We should request user by authToken before rendering the application
	useEffect(() => {
		const requestUser = async () => {
			try {
				if (!didRequest.current) {
					const data = await getUserByToken()
					if (data) {
						dispatch(props.fulfillUser(data))
					}
				}
			} catch (error) {
				console.error(error)
				if (!didRequest.current) {
					dispatch(props.logout())
				}
			} finally {
				setShowSplashScreen(false)
			}

			return () => (didRequest.current = true)
		}

		if (authToken && user) {
			requestUser()
		} else {
			dispatch(props.logout())
			setShowSplashScreen(false)
		}
		// eslint-disable-next-line
	}, [])

	return showSplashScreen ? <LayoutSplashScreen /> : <>{props.children}</>
}

export default connector(AuthInit)
