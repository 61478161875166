import { createSlice } from '@reduxjs/toolkit'

const initialCohortsState = {
	listLoading: false,
	actionsLoading: false,
	totalCount: 0,
	entities: [],
	cohortForEdit: undefined,
	lastError: null,
}
export const callTypes = {
	list: 'list',
	action: 'action',
}

export const cohortsSlice = createSlice({
	name: 'cohorts',
	initialState: initialCohortsState,
	reducers: {
		catchError: (state, action) => {
			state.error = `${action.payload.error}`
			if (action.payload.callType === callTypes.list) {
				state.listLoading = false
			} else {
				state.actionsLoading = false
			}
		},
		startCall: (state, action) => {
			state.error = null
			if (action.payload.callType === callTypes.list) {
				state.listLoading = true
			} else {
				state.actionsLoading = true
			}
		},
		// getCohortById
		cohortFetched: (state, action) => {
			state.actionsLoading = false
			state.cohortForEdit = action.payload.cohortForEdit
			state.error = null
		},
		// findCohorts
		cohortsFetched: (state, action) => {
			const { totalCount, entities } = action.payload
			state.listLoading = false
			state.error = null
			state.entities = entities
			state.totalCount = totalCount
		},
		// createCohort
		cohortCreated: (state, action) => {
			state.actionsLoading = false
			state.error = null
			state.entities.push(action.payload.data)
		},
		// updateCohort
		cohortUpdated: (state, action) => {
			state.error = null
			state.actionsLoading = false
			state.entities = state.entities.map((entity) => {
				if (entity.id === action.payload.id) {
					return { ...entity, ...action.payload }
				}
				return entity
			})
		},
		statusUpdated: (state, action) => {
			state.error = null
			state.actionsLoading = false
			state.entities = state.entities.map((entity) => {
				if (entity.id === action.payload.id) {
					entity.status = action.payload.status
				}
				return entity
			})
		},
		// deleteCohort
		cohortDeleted: (state, action) => {
			state.error = null
			state.actionsLoading = false
			state.entities = state.entities.filter(
				(el) => el.id !== action.payload.id
			)
		},
		// deleteCohorts
		cohortsDeleted: (state, action) => {
			state.error = null
			state.actionsLoading = false
			state.entities = state.entities.filter(
				(el) => !action.payload.ids.includes(el.id)
			)
		},
		// cohortsUpdateState
		cohortsStatusUpdated: (state, action) => {
			state.actionsLoading = false
			state.error = null
			const { ids, status } = action.payload
			state.entities = state.entities.map((entity) => {
				if (ids.findIndex((id) => id === entity.id) > -1) {
					entity.status = status
				}
				return entity
			})
		},
	},
})
