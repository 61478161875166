import { createSlice } from '@reduxjs/toolkit'

const initialCoursesState = {
	listLoading: false,
	actionsLoading: false,
	totalCount: 0,
	entities: [],
	courseForEdit: undefined,
	lastError: null,
}
export const callTypes = {
	list: 'list',
	action: 'action',
}

export const coursesSlice = createSlice({
	name: 'courses',
	initialState: initialCoursesState,
	reducers: {
		catchError: (state, action) => {
			state.error = action.payload.error
			if (action.payload.callType === callTypes.list) {
				state.listLoading = false
			} else {
				state.actionsLoading = false
			}
		},
		startCall: (state, action) => {
			state.error = null
			if (action.payload.callType === callTypes.list) {
				state.listLoading = true
			} else {
				state.actionsLoading = true
			}
		},
		// getCourseById
		courseFetched: (state, action) => {
			state.actionsLoading = false
			state.courseForEdit = action.payload.data
			state.error = null
		},
		// findCourses
		coursesFetched: (state, action) => {
			const { totalCount, entities } = action.payload
			state.listLoading = false
			state.entities = entities
			state.totalCount = totalCount
			state.error = null
		},
		// createCourse
		courseCreated: (state, action) => {
			state.actionsLoading = false
			state.error = null
			state.entities.push(action.payload.data)
		},
		// updateCourse
		courseUpdated: (state, action) => {
			state.error = null
			state.actionsLoading = false
			const course = action.payload.course
			state.courseForEdit = course
			// state.entities = state.entities.map((entity) => {
			//   if (entity.id === action.payload.course.id) {
			//     return action.payload.course;
			//   }
			//   return entity;
			// });
		},
		// updateStatus
		statusUpdated: (state, action) => {
			state.error = null
			state.actionsLoading = false
			state.courseForEdit.status = action.payload.status
		},
		// updateCurriculum
		curriculumUpdated: (state, action) => {
			state.error = null
			state.actionsLoading = false
			state.courseForEdit.curriculums = state.courseForEdit.curriculums.map(
				(entity) => {
					if (entity.id === action.payload.curriculum.id) {
						if (action.payload.curriculum.title)
							entity.title = action.payload.curriculum.title
						if (action.payload.curriculum.description)
							entity.description = action.payload.curriculum.description
						if (action.payload.curriculum.priority)
							entity.priority = action.payload.curriculum.priority
					}
					return entity
				},
			)
		},
		// removeCurriculum
		curriculumDeleted: (state, action) => {
			state.error = null
			state.actionsLoading = false
			state.courseForEdit.curriculums = state.courseForEdit.curriculums.filter(
				(el) => el.id !== action.payload.id,
			)
		},
		// createCurriculum
		curriculumCreated: (state, action) => {
			state.actionsLoading = false
			state.error = null
			state.courseForEdit.curriculums.push(action.payload.data)
		},
		// createLesson
		lessonCreated: (state, action) => {
			state.actionsLoading = false
			state.error = null
			state.courseForEdit.curriculums = state.courseForEdit.curriculums.map(
				(entity) => {
					if (entity.id === action.payload.data.curriculum_id) {
						entity.lessons.push(action.payload.data)
					}
					return entity
				},
			)
		},
		// updateCurriculum
		lessonUpdated: (state, action) => {
			state.error = null
			state.actionsLoading = false
			const curriculum = state.courseForEdit.curriculums.findIndex(
				(c) => c.id === action.payload.lesson.curriculum_id,
			)
			state.courseForEdit.curriculums[
				curriculum
			].lessons = state.courseForEdit.curriculums[curriculum].lessons.map(
				(entity) => {
					if (entity.id === action.payload.lesson.id) {
						entity = action.payload.lesson
					}
					return entity
				},
			)
		},
		// removeLesson
		lessonRemoved: (state, action) => {
			state.actionsLoading = false
			state.error = null
			state.courseForEdit.curriculums = state.courseForEdit.curriculums.map(
				(entity) => {
					if (entity.id === action.payload.curriculum_id) {
						entity.lessons.map(
							(lesson) =>
								lesson.id === action.payload.lesson_id &&
								entity.lessons.splice(lesson, 1),
						)
					}
					return entity
				},
			)
		},
		// deleteCourse
		courseDeleted: (state, action) => {
			state.error = null
			state.actionsLoading = false
			state.entities = state.entities.filter(
				(el) => el.id !== action.payload.id,
			)
		},
		// deleteCourses
		coursesDeleted: (state, action) => {
			state.error = null
			state.actionsLoading = false
			state.entities = state.entities.filter(
				(el) => !action.payload.ids.includes(el.id),
			)
		},
		// coursesUpdateState
		coursesStatusUpdated: (state, action) => {
			state.actionsLoading = false
			state.error = null
			const { ids, status } = action.payload
			state.entities = state.entities.map((entity) => {
				if (ids.findIndex((id) => id === entity.id) > -1) {
					entity.status = status
				}
				return entity
			})
		},
	},
})
