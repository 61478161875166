import axios from 'axios'

export const TUTOR_SETTINGS_URL = process.env.REACT_APP_API_URL + '/settings'
export const MANAGE_SETTINGS_URL =
	process.env.REACT_APP_API_MANAGE_URL + '/settings'
export const STUDENT_SETTINGS_URL =
	process.env.REACT_APP_API_STUDENT_URL + '/settings'

// CREATE =>  POST: add a new setting to the server
export function createSetting(setting) {
	return axios.post(TUTOR_SETTINGS_URL, { setting })
}

// READ
export function getAllSettings() {
	return axios.get(TUTOR_SETTINGS_URL)
}

export function getSettingById(settingId) {
	return axios.get(`${TUTOR_SETTINGS_URL}/${settingId}`)
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findSettings(queryParams) {
	if (queryParams) {
		return axios.get(
			`${TUTOR_SETTINGS_URL}/?filter=${JSON.stringify(queryParams)}`,
		)
	} else {
		return axios.get(`${TUTOR_SETTINGS_URL}`)
	}
}

// UPDATE => PUT: update the procuct on the server
export function updateSettings(settings) {
	return axios.put(`${MANAGE_SETTINGS_URL}`, settings)
}

// UPDATE Status
export function updateStatusForSettings(ids, status) {
	return axios.post(`${TUTOR_SETTINGS_URL}/updateStatusForSettings`, {
		ids,
		status,
	})
}

// DELETE => delete the setting from the server
export function deleteSetting(settingId) {
	return axios.delete(`${TUTOR_SETTINGS_URL}/${settingId}`)
}

// DELETE Settings by ids
export function deleteSettings(ids) {
	return axios.post(`${TUTOR_SETTINGS_URL}/deleteSettings`, { ids })
}

export function fetchBusinessSettings() {
	return axios.get(`${STUDENT_SETTINGS_URL}`)
}
