/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import { checkIsActive } from "../../../../_helpers";
import { shallowEqual, useSelector } from "react-redux";

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu &&
          "menu-item-active"} menu-item-open menu-item-not-hightlighted`
      : "";
  };
  const { isAuthorized, payment_mode, role_level } = useSelector(
    ({ auth, settings }) => ({
      isAuthorized: auth.user != null,
      payment_mode: settings.entities?.payment_mode,
      role_level: auth.user?.role_level
    }),
    shallowEqual
  );

  return (
    <>
      {location.pathname.startsWith("/manage") ? (
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
          {/*begin::1 Level*/}
          <li
            className={`menu-item ${getMenuItemActive(
              "/manage/programs",
              false
            )}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/manage/programs">
              <span className="svg-icon menu-icon">
                <span className="material-icons">school</span>
              </span>
              <span className="menu-text">Programs</span>
            </NavLink>
          </li>
          {/*end::1 Level*/}
          {/*begin::1 Level*/}
          <li
            className={`menu-item ${getMenuItemActive(
              "/manage/courses",
              false
            )}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/manage/courses">
              <span className="svg-icon menu-icon">
                <span className="material-icons">ondemand_video</span>
              </span>
              <span className="menu-text">Courses</span>
            </NavLink>
          </li>
          {/*end::1 Level*/}
          {/*begin::1 Level*/}
          <li
            className={`menu-item ${getMenuItemActive(
              "/manage/categories",
              false
            )}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/manage/categories">
              <span className="svg-icon menu-icon">
                <span className="material-icons">category</span>
              </span>
              <span className="menu-text">Categories</span>
            </NavLink>
          </li>
          {/*end::1 Level*/}

          {/*begin::1 Level*/}
          {role_level <= 1 && (
            <>
              <li
                className={`menu-item ${getMenuItemActive(
                  "/manage/enrolments",
                  false
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/manage/enrolments">
                  <span className="svg-icon menu-icon">
                    <span className="material-icons">groups</span>
                  </span>
                  <span className="menu-text">Enrolments</span>
                </NavLink>
              </li>
              {["subscription", "hybrid"].includes(payment_mode) && (
                <li
                  className={`menu-item ${getMenuItemActive(
                    "/manage/subscriptions",
                    false
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/manage/subscriptions">
                    <span className="svg-icon menu-icon">
                      <span className="material-icons">card_membership</span>
                    </span>
                    <span className="menu-text">Subscriptions</span>
                  </NavLink>
                </li>
              )}
            </>
          )}
          {/*end::1 Level*/}
          <li
            className={`menu-item ${getMenuItemActive(
              "/manage/profile",
              false
            )}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/manage/profile">
              <span className="svg-icon menu-icon">
                <span className="material-icons">account_circle</span>
              </span>
              <span className="menu-text">Profile</span>
            </NavLink>
          </li>
          {/*end::1 Level*/}
          {/*end::1 Level*/}
          <li
            className={`menu-item ${getMenuItemActive(
              "/manage/cohorts",
              false
            )}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/manage/cohorts">
              <span className="svg-icon menu-icon">
                <span className="material-icons">tune</span>
              </span>
              <span className="menu-text">Cohorts</span>
            </NavLink>
          </li>
          {/*end::1 Level*/}
          {/*end::1 Level*/}
          <li
            className={`menu-item ${getMenuItemActive(
              "/manage/upload",
              false
            )}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/manage/upload">
              <span className="svg-icon menu-icon">
                <span className="material-icons">tune</span>
              </span>
              <span className="menu-text">Upload</span>
            </NavLink>
          </li>
          {/*end::1 Level*/}
          {/*end::1 Level*/}
          <li
            className={`menu-item ${getMenuItemActive(
              "/manage/settings",
              false
            )}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/manage/settings">
              <span className="svg-icon menu-icon">
                <span className="material-icons">tune</span>
              </span>
              <span className="menu-text">Settings</span>
            </NavLink>
          </li>
          {/*end::1 Level*/}
        </ul>
      ) : (
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
          {/*begin::1 Level*/}
          <li
            className={`menu-item ${getMenuItemActive("/programs", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/programs">
              <span className="svg-icon menu-icon">
                <span className="material-icons">school</span>
              </span>
              <span className="menu-text">Programs</span>
            </NavLink>
          </li>
          {/*end::1 Level*/}
          {/*begin::1 Level*/}
          <li
            className={`menu-item ${getMenuItemActive("/courses", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/courses">
              <span className="svg-icon menu-icon">
                <span className="material-icons">ondemand_video</span>
              </span>
              <span className="menu-text">Courses</span>
            </NavLink>
          </li>
          {/*end::1 Level*/}
          {/*begin::1 Level*/}
          {isAuthorized && (
            <>
              {["subscription", "hybrid"].includes(payment_mode) && (
                <>
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/subscriptions",
                      false
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/subscriptions">
                      <span className="svg-icon menu-icon">
                        <span className="material-icons">price_change</span>
                      </span>
                      <span className="menu-text">Plans</span>
                    </NavLink>
                  </li>
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/enrolments",
                      false
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/enrolments">
                      <span className="svg-icon menu-icon">
                        <span className="material-icons">price_change</span>
                      </span>
                      <span className="menu-text">Enrolments</span>
                    </NavLink>
                  </li>
                </>
              )}
              <li
                className={`menu-item ${getMenuItemActive("/library", false)}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/library">
                  <span className="svg-icon menu-icon">
                    <span className="material-icons">video_library</span>
                  </span>
                  <span className="menu-text">My Library</span>
                </NavLink>
              </li>
              {/*end::1 Level*/}
              <li
                className={`menu-item ${getMenuItemActive("/profile", false)}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/profile">
                  <span className="svg-icon menu-icon">
                    <span className="material-icons">account_circle</span>
                  </span>
                  <span className="menu-text">Profile</span>
                </NavLink>
              </li>
              {/*end::1 Level*/}
            </>
          )}
          {/*end::1 Level*/}
        </ul>
      )}
    </>
  );
}
