import * as requestFromServer from './settingsCrud'
import { settingsSlice, callTypes } from './settingsSlice'

const { actions } = settingsSlice

export const fetchSettings = (queryParams) => async (dispatch) => {
	dispatch(actions.startCall({ callType: callTypes.list }))
	try {
		const entities = await requestFromServer.findSettings(queryParams)
		dispatch(actions.allSettingsFetched({ entities }))
	} catch (error) {
		dispatch(actions.catchError({ error, callType: callTypes.list }))
	}
}

export const fetchSetting = (id) => async (dispatch) => {
	if (!id) {
		return dispatch(actions.settingFetched({ settingForEdit: undefined }))
	}

	dispatch(actions.startCall({ callType: callTypes.action }))
	try {
		const settingForEdit = await requestFromServer.getSettingById(id)
		dispatch(actions.settingFetched({ settingForEdit }))
	} catch (error) {
		dispatch(actions.catchError({ error, callType: callTypes.action }))
	}
}

export const updateSettings = (setting) => async (dispatch) => {
	dispatch(actions.startCall({ callType: callTypes.action }))
	try {
		await requestFromServer.updateSettings(setting)
		dispatch(actions.settingUpdated({ setting }))
	} catch (error) {
		dispatch(actions.catchError({ error, callType: callTypes.action }))
	}
}

export const fetchBusinessSettings = () => async (dispatch) => {
	try {
		const entities = await requestFromServer.fetchBusinessSettings()
		dispatch(actions.settingsFetched({ entities }))
	} catch (error) {
		dispatch(actions.catchError({ error, callType: callTypes.list }))
	}
}
