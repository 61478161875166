import React, { Suspense, lazy, useState, useEffect } from 'react'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import { Redirect, Switch, Route, useRouteMatch } from 'react-router-dom'
import {
	fetchSettings,
	fetchBusinessSettings,
} from './modules/Settings/_redux/settings/settingsActions'
import {
	fetchProfile,
	tutorLicense,
} from './modules/Settings/_redux/profile/profileActions'
import { LayoutSplashScreen } from '../_metronic/layout'
import store from '../redux/store'
import swal from 'sweetalert2'
import axios from 'axios'
import { LoadingDialog } from '../_metronic/_partials/controls'
import Swal from 'sweetalert2'

const PlanPage = lazy(() =>
	import('./modules/Student/Subscriptions/SubscriptionPage')
)
const CoursePage = lazy(() => import('./modules/Course/pages/CoursePage'))
const SettingsPage = lazy(() => import('./modules/Settings/SettingsPage'))
const UploadPage = lazy(() => import('./pages/UploadPage'))
const StudentCoursePage = lazy(() =>
	import('./modules/Student/Courses/pages/CourseList')
)
const CheckoutPage = lazy(() =>
	import('./modules/Student/Courses/pages/CheckoutPage')
)
const LibraryPage = lazy(() =>
	import('./modules/Student/Library/pages/LibraryPage')
)
const EnrolmentPage = lazy(() =>
	import('./modules/Student/Enrolments/pages/enrolments/EnrolmentsPage')
)
const EnrolmentsPage = lazy(() =>
	import('./modules/Enrolments/pages/enrolments/EnrolmentsPage')
)
const SubscriptionsPage = lazy(() =>
	import('./modules/Subscriptions/pages/subscriptions/SubcriptionsPage')
)
const CohortsPage = lazy(() =>
	import('./modules/Cohort/pages/cohorts/ChohortsPage')
)
const LicensePage = lazy(() => import('./pages/LicensePage'))
const PersonaInformation = lazy(() =>
	import('./modules/Settings/PersonaInformation')
)

export function BasePage() {
	const { isAuthorized } = useSelector(
		({ auth }) => ({
			isAuthorized: auth.user != null,
		}),
		shallowEqual
	)
	const dispatch = useDispatch()
	useEffect(() => {
		if (isAuthorized) {
			dispatch(fetchBusinessSettings())
		}
	}, [dispatch, isAuthorized])
	return (
		<Switch>
			<Redirect exact from='/' to='/courses' />
			<Route path='/manage' component={TutorPage} />
			<Route path='/zoom1' component={Zoom} />
			<Route path='/zoom2' component={Zoom2} />
			<StudentPage />
		</Switch>
	)
}
function StudentPage() {
	const { isAuthorized } = useSelector(
		({ auth }) => ({
			isAuthorized: auth.user != null,
		}),
		shallowEqual
	)
	return (
		<Suspense fallback={<LayoutSplashScreen />}>
			<Switch>
				<Route path='/courses' component={StudentCoursePage} />
				<Route path='/programs' component={StudentCoursePage} />
				{isAuthorized ? (
					<Switch>
						<Route path='/subscriptions' component={PlanPage} />
						<Route path='/enrolments' component={EnrolmentPage} />
						<Route path='/checkout/:type/:id' component={CheckoutPage} />
						<Route path='/library' component={LibraryPage} />
						<Route path='/profile' component={PersonaInformation} />
					</Switch>
				) : (
					<Redirect to='/courses' />
				)}
			</Switch>
		</Suspense>
	)
}
function TutorPage() {
	const { isAuthorized } = useSelector(
		({ auth }) => ({
			isAuthorized: auth.user != null,
		}),
		shallowEqual
	)
	const [accountSwitch, setAccountSwitch] = useState(true)
	const { settings, user, profile, isLoading } = useSelector(
		(state) => ({
			isLoading: state.profile.actionsLoading,
			settings: state.settings.entities,
			user: state.auth.user,
			profile: state.profile.entities,
		}),
		shallowEqual
	)
	const dispatch = useDispatch()
	useEffect(() => {
		dispatch(fetchSettings())
		dispatch(fetchProfile())
	}, [dispatch])
	useEffect(() => {
		if (
			settings &&
			settings.tutor_license === true &&
			profile.is_licensed !== true
		)
			setAccountSwitch(false)
		else setAccountSwitch(true)
	}, [settings, profile])
	const updateLicense = (data) => {
		dispatch(tutorLicense(data)).then(() => {
			let error_state = store.getState().profile.error
			if (error_state !== null) {
				swal.fire('Oops', error_state, 'error')
			} else {
				swal.fire('Welcome', 'Go ahead and create great contents', 'success')
			}
		})
	}
	const { path } = useRouteMatch()
	return (
		<Suspense fallback={<LayoutSplashScreen />}>
			{!isAuthorized ? (
				<Redirect to='/auth/login' />
			) : user.role_level > 4 ? (
				<Redirect to='/courses' />
			) : accountSwitch === false ? (
				<LicensePage
					modalShow={true}
					email={user.email}
					settings={settings}
					updateLicense={updateLicense}
					isLoading={isLoading}
				/>
			) : (
				<Switch>
					<Redirect exact from={path} to={`${path}/courses`} />
					<Route path={`${path}/enrolments`} component={EnrolmentsPage} />
					<Route path={`${path}/subscriptions`} component={SubscriptionsPage} />
					<Route path={`${path}/cohorts`} component={CohortsPage} />
					<Route path={`${path}/profile`} component={PersonaInformation} />
					<Route path={`${path}/upload`} component={UploadPage} />
					{user.role_level <= 4 ? (
						<Route path={`${path}/settings`} component={SettingsPage} />
					) : (
						<Redirect from={`${path}/settings`} to={`${path}/courses`} />
					)}
					<Route path={`${path}`} component={CoursePage} />
					<Redirect to='/error/error-v1' />
				</Switch>
			)}
		</Suspense>
	)
}

const Zoom = () => {
	useEffect(() => {
		axios
			.get(process.env.REACT_APP_API_URL + '/zoom_link')
			.then((res) =>
				res.ZoomLink
					? (window.location.href = res.ZoomLink)
					: Swal.fire({
							icon: 'error',
							html: 'No zoom link set',
					  })
			)
			.catch((err) =>
				Swal.fire({
					icon: 'error',
					html: err.message,
				})
			)
	}, [])
	return <LoadingDialog />
}
const Zoom2 = () => {
	useEffect(() => {
		axios
			.get(process.env.REACT_APP_API_URL + '/zoom_link2')
			.then((res) =>
				res.ZoomLink2
					? (window.location.href = res.ZoomLink2)
					: Swal.fire({
							icon: 'error',
							html: 'No zoom link set',
					  })
			)
			.catch((err) =>
				Swal.fire({
					icon: 'error',
					html: err.message,
				})
			)
	}, [])
	return <LoadingDialog />
}
