/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from 'react'
import { useLocation } from 'react-router'
import { Link } from 'react-router-dom'
// import { Link } from 'react-router-dom'
import { checkIsActive } from '../../../../_helpers'
import { shallowEqual, useSelector } from 'react-redux'

export function HeaderMenu({ layoutProps }) {
	const location = useLocation()
	const getMenuItemActive = (url) => {
		return checkIsActive(location, url) ? 'menu-item-active' : ''
	}
	const { role_level, account_switch } = useSelector(
		({ auth, settings }) => ({
			role_level: auth.user?.role_level,
			account_switch: settings?.entities.account_switch,
		}),
		shallowEqual
	)

	return (
		<div
			id='kt_header_menu'
			className={`header-menu header-menu-mobile ${layoutProps.ktMenuClasses}`}
			{...layoutProps.headerMenuAttributes}
		>
			{/*begin::Header Nav*/}
			<ul className={`menu-nav ${layoutProps.ulClasses}`}>
				{/*begin::1 Level*/}
				{role_level <= 4 &&
					account_switch &&
					window.location.origin !== 'https://thenightschool.ca' && (
						<li className={`menu-item menu-item-rel ${getMenuItemActive('/')}`}>
							<Link
								className='menu-link'
								to={location.pathname.startsWith('/manage') ? '/' : '/manage'}
							>
								<span className='menu-text'>
									Switch to{' '}
									{location.pathname.startsWith('/manage')
										? 'Student'
										: 'Instructor'}
								</span>
								{layoutProps.rootArrowEnabled && <i className='menu-arrow' />}
							</Link>
						</li>
					)}
				{/*end::1 Level*/}
			</ul>
			{/*end::Header Nav*/}
		</div>
	)
}
