import * as requestFromServer from './profileCrud'
import { profileSlice, callTypes } from './profileSlice'

const { actions } = profileSlice

export const fetchProfile = () => async (dispatch) => {
	dispatch(actions.startCall({ callType: callTypes.list }))
	try {
		const entities = await requestFromServer.findProfile()
		dispatch(actions.profileFetched({ entities }))
	} catch (error) {
		dispatch(actions.catchError({ error, callType: callTypes.list }))
		throw error
	}
}

export const fetchStudentProfile = () => async (dispatch) => {
	dispatch(actions.startCall({ callType: callTypes.list }))
	try {
		const entities = await requestFromServer.findStudentProfile()
		dispatch(actions.profileFetched({ entities }))
	} catch (error) {
		dispatch(actions.catchError({ error, callType: callTypes.list }))
		throw error
	}
}

export const updateProfile = (profile) => async (dispatch) => {
	dispatch(actions.startCall({ callType: callTypes.action }))
	try {
		await requestFromServer.updateProfile(profile)
		dispatch(actions.profileUpdated({ profile }))
	} catch (error) {
		dispatch(actions.catchError({ error, callType: callTypes.action }))
		throw error
	}
}

export const tutorLicense = (reference) => async (dispatch) => {
	dispatch(actions.startCall({ callType: callTypes.action }))
	try {
		await requestFromServer.tutorLicense(reference)
		dispatch(actions.licenseUpdated(true))
	} catch (error) {
		dispatch(actions.catchError({ error, callType: callTypes.action }))
		throw error
	}
}
