export function removeCSSClass(ele, cls) {
	const reg = new RegExp('(\\s|^)' + cls + '(\\s|$)')
	ele.className = ele.className.replace(reg, ' ')
}

export function addCSSClass(ele, cls) {
	ele.classList.add(cls)
}
export const toAbsoluteUrl = (pathname) =>
	(process.env.NODE_ENV === 'developments'
		? process.env.PUBLIC_URL
		: process.env.REACT_APP_BUCKET_URL + '/metronic/v7') + pathname
